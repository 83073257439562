import * as React from 'react';
import {Box, Link} from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

function ProductSmokingHero() {
  return (
    <Container
      component="section"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 9 }}
    >
      <Button
        role="none"
        component="div"
        sx={{
          border: '4px solid currentColor',
          borderRadius: 0,
          height: 'auto',
          py: 2,
          px: 5,
          cursor: 'default'
        }}
      >
        <Typography variant="h4" component="span">
          Thanks for dropping by!
        </Typography>
      </Button>
      <Typography variant="subtitle1" sx={{ my: 3 }}>
        If you want to get in touch, you can email me at{" "}
        <Link href="mailto:willow@willowpowell.com">willow@willowpowell.com</Link>
        {" "}or checkout out my <Link href="https://www.linkedin.com/in/willow-powell-0bb948104/">LinkedIn profile</Link>.
        Have a great day :)
      </Typography>
      <Box
        component="img"
        src="/img/build-black.svg"
        alt="gear"
        sx={{ width: 60 }}
      />
    </Container>
  );
}

export default ProductSmokingHero;
