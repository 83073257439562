import * as React from 'react';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';

import backgroundImage from './binary-background.jpg'

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: 'rgb(36,40,45)', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography
        color="inherit"
        align="center"
        variant="h2"
        marked="center"
        sx={{
          backgroundColor: 'rgba(15,15,15,0.6)',
          padding: 3,
        }}
      >
        Willow Powell, <br />Software Engineer
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{
          mb: 4,
          mt: 10,
          backgroundColor: 'rgba(15,15,15,0.9)',
          padding: 2,
        }}
      >
        I solve problems.
      </Typography>
    </ProductHeroLayout>
  );
}
