import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Typography, IconButton } from '@mui/material'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';

const rightLink = {
  fontSize: 16,
  color: 'common.white',
  ml: 3,
};

function AppAppBar() {
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ flex: 1 }} />
          <Typography
            variant="h6"
            underline="none"
            color="inherit"
            sx={{ fontSize: 24 }}
          >
            Willow Powell
          </Typography>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              component={Link}
              href="mailto:willow@willowpowell.com"
              sx={rightLink}
              aria-label="Email Me"
            >
              <MailOutlineIcon />
            </IconButton>
            <IconButton
              component={Link}
              href="https://www.linkedin.com/in/willow-powell-0bb948104/"
              sx={rightLink}
              aria-label="Find me on LinkedIn"
            >
              <LinkedInIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
