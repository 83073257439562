import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import ProductCategories from './onepirate/modules/views/ProductCategories';
import ProductSmokingHero from './onepirate/modules/views/ProductSmokingHero';
import AppFooter from './onepirate/modules/views/AppFooter';
import ProductHero from './onepirate/modules/views/ProductHero';
import ProductValues from './onepirate/modules/views/ProductValues';
import AppAppBar from './onepirate/modules/views/AppAppBar';
import theme from './theme';

export default function App() {
  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppAppBar />
        <ProductHero />
        <ProductValues />
        <ProductCategories />
        <ProductSmokingHero />
        <AppFooter />
      </ThemeProvider>
  );
}
