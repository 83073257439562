import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ mt: 15, mb: 30, display: 'flex', position: 'relative' }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/img/Code.svg"
                alt="Front end"
                sx={{ height: 80 }}
              />
              <Typography variant="h6" sx={{ my: 5, textAlign: 'center' }}>
                Front end development
              </Typography>
              <Typography variant="h5">
                I have strong skills with React, TypeScript, and CSS.
                I have built dozens of user interfaces over the years, both from business-only requirements and from detailed designs.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="img/Plan.svg"
                alt="Back end"
                sx={{ height: 80 }}
              />
              <Typography variant="h6" sx={{ my: 5, textAlign: 'center' }}>
                Back end development
              </Typography>
              <Typography variant="h5">
                I am experienced with API development in Django, FastAPI, and Flask.
                I am comfortable working in an object-oriented paradigm and love conversations about data models.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="img/Rocket.svg"
                alt="Rocket"
                sx={{ height: 80 }}
              />
              <Typography variant="h6" sx={{ my: 5, textAlign: 'center' }}>
                Great to work with
              </Typography>
              <Typography variant="h5">
                I have excellent communication skills and love collaborating with people of different skillsets to build amazing products together.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
