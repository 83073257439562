import * as React from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

import { IconButton } from '@mui/material'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


function Copyright() {
  return (
    <Typography>
      © Willow {new Date().getFullYear()}
    </Typography>
  );
}

export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: 'flex', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ my: 8, display: 'flex' }}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={4} md={3}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              spacing={2}
              sx={{ height: 120 }}
            >
              <Grid item sx={{ display: 'flex' }}>
            <IconButton
              component={Link}
              href="mailto:willow@willowpowell.com"
              // sx={rightLink}
              aria-label="Email Me"
            >
              <MailOutlineIcon />
            </IconButton>
            <IconButton
              component={Link}
              href="https://www.linkedin.com/in/willow-powell-0bb948104/"
              // sx={rightLink}
              aria-label="Find me on LinkedIn"
            >
              <LinkedInIcon />
            </IconButton>
              </Grid>
              <Grid item>
                <Copyright />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
